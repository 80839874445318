import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CheckCircle, ArrowLeft } from "lucide-react";
import DashboardLayout from "@/components/DashboardLayout";
import { useAuth } from "@/hooks/useAuth";

const BACKEND_URL = "http://localhost:8000";

export default function ConnectShopify() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const [shopUrl, setShopUrl] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [shopName, setShopName] = useState("");
  const [salesData, setSalesData] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [productExportPath, setProductExportPath] = useState<string | null>(null);
  const [exportMetadata, setExportMetadata] = useState<any[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get("shop");

    if (shop) {
      setIsConnected(true);
      setShopName(shop);
      localStorage.setItem("shop", shop);
    }
  }, [location]);

  useEffect(() => {
    if (user?.uid) {
      const tenantId = user.uid;
  
      fetch(`${BACKEND_URL}/shopify/user/shopify-info?uid=${tenantId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.shopify_shop) {
            setIsConnected(true);
            setShopName(data.shopify_shop); // ✅ Update from `data.shop` to `data.shopify_shop`
            localStorage.setItem("shop", data.shopify_shop);
          }
        })
        .catch((err) => {
          console.error("Failed to check Shopify connection:", err);
        });
    }
  }, [user]);
  
  
  const handleConnect = () => {
    if (!shopUrl) {
      alert("Please enter your Shopify store URL.");
      return;
    }
    setIsConnecting(true);
    // window.location.href = `${BACKEND_URL}/shopify/auth/shopify/start?shop=${shopUrl}&email=${user?.email}`;
    window.location.href = `${BACKEND_URL}/shopify/auth/shopify/start?shop=${shopUrl}&email=${user?.email}&uid=${user?.uid}`;

  };

  const fetchSalesData = async () => {
    if (!user?.email) {
      alert("Cannot identify tenant. Please log in again.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/shopify/orders?shop=${shopName}&tenant_id=${user.email}`);
      const data = await response.json();

      if (!response.ok) throw new Error(data.detail || "Failed to fetch sales data.");
      setSalesData(data.orders || []);
    } catch (error) {
      console.error("Error fetching sales data:", error);
      alert("Failed to fetch sales data.");
    }
    setLoading(false);
  };

  const fetchProducts = async () => {
    if (!user?.email) {
      alert("Cannot identify tenant. Please log in again.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/shopify/export-products?shop=${shopName}&tenant_id=${user.email}`);
      const data = await response.json();

      if (response.ok) {
        setProducts(data.products || []);
        setProductExportPath(data.gcs_location);
        await reloadMetadata(); // refresh table
      } else {
        throw new Error(data.detail || "Unknown error occurred.");
      }
    } catch (error) {
      console.error("Error exporting products:", error);
      alert("Something went wrong while syncing products.");
    }
    setLoading(false);
  };

  const fetchOrders = async () => {
    if (!user?.email) {
      alert("Cannot identify tenant. Please log in again.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/shopify/export-orders?shop=${shopName}&tenant_id=${user.email}`);
      const data = await response.json();

      if (!response.ok) throw new Error(data.detail || "Failed to export orders.");
      alert("✅ Orders exported successfully!");
      await reloadMetadata(); // refresh table
    } catch (error) {
      console.error("Error exporting orders:", error);
      alert("Something went wrong while exporting orders.");
    }
    setLoading(false);
  };

  const reloadMetadata = async () => {
    try {
      const res = await fetch(`${BACKEND_URL}/shopify/export-metadata?shop=${shopName}&tenant_id=${user.email}`);
      const data = await res.json();
      setExportMetadata(data.metadata || []);
    } catch (err) {
      console.error("Failed to reload export metadata:", err);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 py-12">
        <Button variant="ghost" className="mb-8 gap-2" onClick={() => navigate("/welcome")}>
          <ArrowLeft className="h-4 w-4" /> Back to welcome
        </Button>

        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Connect your Shopify store</h1>
          <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
            Connecting your store enables us to analyze your sales, products, and customer data.
          </p>
        </div>

        {isConnected ? (
          <div className="text-center py-10 space-y-10">
            <div className="inline-flex items-center justify-center rounded-full bg-green-100 p-6 mx-auto">
              <CheckCircle className="h-12 w-12 text-green-600" />
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-2">Your store is connected</h2>
              <p className="text-muted-foreground max-w-md mx-auto">
                We’re now syncing data from <strong>{shopName}</strong>. You can fetch sales or product data to begin analyzing your store’s performance.
              </p>
            </div>

            <div className="flex justify-center gap-4 flex-wrap">
              <Button onClick={fetchSalesData} disabled={loading}>
                {loading ? "Fetching..." : "Fetch Sales Data"}
              </Button>
              <Button onClick={fetchProducts} disabled={loading} variant="outline">
                {loading ? "Fetching..." : "Export Products"}
              </Button>
              <Button onClick={fetchOrders} disabled={loading} variant="secondary">
                {loading ? "Fetching..." : "Export Orders"}
              </Button>
            </div>

            {exportMetadata.length > 0 && (
              <div className="bg-muted rounded-lg p-6 text-left max-w-4xl mx-auto mt-8">
                <h3 className="text-lg font-semibold mb-3">Export History</h3>
                <div className="mt-4 border rounded-lg overflow-auto">
                  <table className="min-w-full text-sm">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-4 py-2 font-medium">Table</th>
                        <th className="px-4 py-2 font-medium">Records</th>
                        <th className="px-4 py-2 font-medium">Exported At</th>
                        <th className="px-4 py-2 font-medium">Request ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exportMetadata.map((row) => (
                        <tr key={row.request_id} className="border-t">
                          <td className="px-4 py-2">{row.table_name}</td>
                          <td className="px-4 py-2">{row.record_count}</td>
                          <td className="px-4 py-2">{new Date(row.export_timestamp).toLocaleString()}</td>
                          <td className="px-4 py-2 text-xs break-all text-muted-foreground">
                            {row.request_id}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="bg-card rounded-lg border p-8 shadow-sm max-w-2xl mx-auto">
            <label htmlFor="store-url" className="block text-sm font-medium text-foreground mb-2">
              Your Shopify store URL
            </label>
            <div className="flex gap-2">
              <Input
                id="store-url"
                value={shopUrl}
                onChange={(e) => setShopUrl(e.target.value)}
                placeholder="yourstore.myshopify.com"
                className="flex-1"
              />
              <Button onClick={handleConnect} disabled={isConnecting || !shopUrl}>
                {isConnecting ? "Connecting..." : "Connect"}
              </Button>
            </div>
            <p className="text-sm text-muted-foreground mt-2">Example: yourstore.myshopify.com</p>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}
