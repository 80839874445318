import * as React from "react";

export function ToastProvider({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

export function Toast({ children }: { children: React.ReactNode }) {
  return <div className="toast">{children}</div>;
}

export function ToastTitle({ children }: { children: React.ReactNode }) {
  return <strong>{children}</strong>;
}

export function ToastDescription({ children }: { children: React.ReactNode }) {
  return <p>{children}</p>;
}

export function ToastClose() {
  return <button className="toast-close">✖</button>;
}

export function ToastViewport() {
  return <div className="toast-viewport">Viewport</div>;
}

export default Toast;
