import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import { 
  LineChart, 
  ShoppingCart, 
  Users, 
  ArrowUpRight, 
  RefreshCw, 
  TrendingUp,
  BarChart3,
  PieChart
} from 'lucide-react';

export default function About() {
  const solutions = [
    {
      icon: <ShoppingCart className="h-8 w-8" />,
      title: "Optimize Customer Acquisition",
      description: "Know exactly which marketing channels drive your highest value customers and optimize your ad spend accordingly.",
      benefits: [
        "Reduce customer acquisition costs by up to 30%",
        "Identify top-performing ad creatives",
        "Track attribution across the entire customer journey"
      ]
    },
    {
      icon: <Users className="h-8 w-8" />,
      title: "Increase Retention and LTV",
      description: "Segment your customers based on purchase behavior and target them with personalized campaigns to maximize their lifetime value.",
      benefits: [
        "Increase repeat purchase rate by 25%",
        "Create targeted retention campaigns",
        "Predict customer churn before it happens"
      ]
    },
    {
      icon: <BarChart3 className="h-8 w-8" />,
      title: "Improve Merchandising",
      description: "Understand which products drive the most revenue and profit, and optimize your inventory and product placement accordingly.",
      benefits: [
        "Identify your most profitable products",
        "Discover winning product combinations",
        "Optimize pricing and discount strategies"
      ]
    },
    {
      icon: <RefreshCw className="h-8 w-8" />,
      title: "Automate Your Reporting",
      description: "Save hours every week with automated reports and dashboards that give you the insights you need, when you need them.",
      benefits: [
        "Customizable reporting templates",
        "Scheduled reports delivered to your inbox",
        "Share insights with your team effortlessly"
      ]
    }
  ];

  const userTypes = [
    {
      icon: <LineChart className="h-8 w-8" />,
      title: "For E-commerce Brands",
      description: "Whether you're just starting out or managing multiple stores, Dougie Analytics provides the insights you need to grow your business.",
      features: [
        "Unified dashboard for all your sales channels",
        "Customer lifetime value analysis",
        "Marketing ROI optimization"
      ]
    },
    {
      icon: <PieChart className="h-8 w-8" />,
      title: "For Agencies",
      description: "Manage all your client accounts from a single dashboard and deliver data-driven strategies that drive results.",
      features: [
        "Multi-client reporting",
        "White-label dashboards",
        "Client-specific performance alerts"
      ]
    },
    {
      icon: <TrendingUp className="h-8 w-8" />,
      title: "For Marketing Teams",
      description: "Make data-backed decisions for your campaigns and demonstrate the impact of your marketing efforts.",
      features: [
        "Campaign performance tracking",
        "Creative content analysis",
        "Marketing channel optimization"
      ]
    }
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      
      <main className="flex-1 pt-20">
        {/* Hero Section */}
        <section className="relative py-20 px-4">
          <div className="absolute inset-0 -z-10 hero-gradient"></div>
          <div className="max-w-5xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-display font-bold leading-tight mb-6 animate-fade-up">
              Analytics Solutions <span className="gradient-text">For Modern Businesses</span>
            </h1>
            <p className="text-lg md:text-xl text-muted-foreground max-w-2xl mx-auto mb-8 animate-fade-up" style={{ animationDelay: '0.1s' }}>
              Dougie Analytics helps e-commerce brands, agencies, and marketing teams make data-driven decisions to grow their business.
            </p>
          </div>
        </section>
        
        {/* Solutions Section */}
        <section className="py-16 px-4 bg-gray-50">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-display font-bold mb-4">Our Solutions</h2>
              <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
                Tailored analytics solutions designed to solve your specific business challenges.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {solutions.map((solution, index) => (
                <div key={index} className="glass-panel-premium rounded-xl p-8 hover:shadow-lg transition-shadow">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-14 h-14 rounded-full bg-gradient-to-br from-primary/20 to-accent/20 flex items-center justify-center text-primary">
                      {solution.icon}
                    </div>
                    <h3 className="text-2xl font-medium">{solution.title}</h3>
                  </div>
                  <p className="text-muted-foreground mb-6">{solution.description}</p>
                  <ul className="space-y-2 mb-6">
                    {solution.benefits.map((benefit, bIndex) => (
                      <li key={bIndex} className="flex items-start gap-2">
                        <div className="text-primary mt-1">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3334 4L6.00002 11.3333L2.66669 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                  <a href="#" className="inline-flex items-center text-primary font-medium hover:underline">
                    Learn more
                    <ArrowUpRight className="h-4 w-4 ml-1" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* For Different Users Section */}
        <section className="py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-display font-bold mb-4">Who We Serve</h2>
              <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
                Specialized analytics solutions for different business needs.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {userTypes.map((user, index) => (
                <div key={index} className="glass-panel rounded-xl p-8 hover:shadow-lg transition-shadow">
                  <div className="w-14 h-14 rounded-full bg-gradient-to-br from-primary/20 to-accent/20 flex items-center justify-center text-primary mb-4">
                    {user.icon}
                  </div>
                  <h3 className="text-xl font-medium mb-2">{user.title}</h3>
                  <p className="text-muted-foreground mb-6">{user.description}</p>
                  <ul className="space-y-2">
                    {user.features.map((feature, fIndex) => (
                      <li key={fIndex} className="flex items-start gap-2">
                        <div className="text-primary mt-1">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3334 4L6.00002 11.3333L2.66669 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* CTA Section */}
        <section className="py-16 px-4 bg-gray-50">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-display font-bold mb-4">Ready to transform your analytics?</h2>
            <p className="text-lg text-muted-foreground max-w-2xl mx-auto mb-8">
              Join hundreds of businesses that are making data-driven decisions with Dougie Analytics.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button className="rounded-full bg-gradient-to-r from-primary to-accent text-white px-8 py-3 text-sm font-medium hover:opacity-90 transition-all">
                Request a Demo
              </button>
              <button className="rounded-full bg-white border border-gray-200 text-foreground px-8 py-3 text-sm font-medium hover:bg-gray-50 transition-all">
                View Pricing
              </button>
            </div>
          </div>
        </section>
      </main>
      
      <Footer />
    </div>
  );
}
