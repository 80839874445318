import { Button } from '@/components/ui/button';
import { CheckCircle2, ArrowRight } from 'lucide-react';
import DashboardLayout from '@/components/DashboardLayout';
import { useNavigate } from 'react-router-dom';

export default function Welcome() {
  const navigate = useNavigate();
  
  const handleGetStarted = () => {
    navigate('/connect-shopify');
  };
  
  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Welcome to Dougie Analytics!</h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            Let's get your account set up so you can start making data-driven decisions to grow your business.
          </p>
        </div>
        
        <div className="bg-card rounded-lg border p-8 shadow-sm mb-8">
          <div className="space-y-8">
            <div className="flex items-start gap-4">
              <div className="bg-primary/10 rounded-full p-2 mt-1">
                <CheckCircle2 className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Account created</h3>
                <p className="text-muted-foreground">Your account has been created successfully.</p>
              </div>
            </div>
            
            <div className="flex items-start gap-4">
              <div className="bg-muted rounded-full p-2 mt-1">
                <div className="h-6 w-6 flex items-center justify-center text-muted-foreground font-semibold">2</div>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Connect your Shopify store</h3>
                <p className="text-muted-foreground mb-4">
                  Connecting your Shopify store will allow us to analyze your products, orders, and customer data.
                </p>
                <Button onClick={handleGetStarted} className="gap-2">
                  Connect Shopify <ArrowRight className="h-4 w-4" />
                </Button>
              </div>
            </div>
            
            <div className="flex items-start gap-4 opacity-60">
              <div className="bg-muted rounded-full p-2 mt-1">
                <div className="h-6 w-6 flex items-center justify-center text-muted-foreground font-semibold">3</div>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Connect ad platforms</h3>
                <p className="text-muted-foreground">
                  After connecting Shopify, you'll be able to connect your advertisement platforms like Instagram, Facebook, Pinterest, and TikTok.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-card rounded-lg border shadow-sm overflow-hidden">
          <div className="p-6">
            <h3 className="text-lg font-semibold mb-2">What to expect next</h3>
            <p className="text-muted-foreground mb-4">
              Once your data sources are connected, Dougie Analytics will:
            </p>
            <ul className="space-y-2 list-disc pl-5 text-muted-foreground">
              <li>Automatically analyze your marketing and sales data</li>
              <li>Create custom dashboards showing your key metrics</li>
              <li>Provide insights to help optimize your ad spend</li>
              <li>Show you which channels drive the most revenue</li>
            </ul>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}