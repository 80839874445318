import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  User
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  serverTimestamp
} from "firebase/firestore";
import { auth } from "@/firebaseConfig";

// Define TypeScript Types
interface TenantUser {
  uid: string;
  email: string;
  displayName: string;
  photoURL?: string;
  tenantId?: string;
  createdAt?: any;
}

export function useAuth() {
  const [user, setUser] = useState<TenantUser | null>(null);
  const [tenant, setTenant] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser: User | null) => {
      console.log("🧑 Firebase User:", firebaseUser);

      if (!firebaseUser) {
        setUser(null);
        setTenant(null);
        setLoading(false);
        return;
      }

      try {
        await firebaseUser.getIdToken(true); // Get fresh ID token

        const userRef = doc(db, "users", firebaseUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const existingUser = userSnap.data() as TenantUser;
          setUser(existingUser);
          setTenant(existingUser.tenantId || "default_tenant");
          console.log("✅ Existing user loaded from Firestore");
        } else {
          const newUser: TenantUser = {
            uid: firebaseUser.uid,
            email: firebaseUser.email || "",
            displayName: firebaseUser.displayName || "Unknown User",
            photoURL: firebaseUser.photoURL || "",
            tenantId: "default_tenant",
            createdAt: serverTimestamp()
          };

          await setDoc(userRef, newUser);
          setUser(newUser);
          setTenant("default_tenant");
          console.log("✨ New user document created in Firestore");
        }
      } catch (error) {
        console.error("❌ Error in useAuth:", error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    tenant,
    loading,
    signOut: () => signOut(auth)
  };
}
