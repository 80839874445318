import { useState } from 'react';
import { toast } from "sonner";

export default function Hero() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log("🚨 ENV:", import.meta.env.MODE);
    console.log("✅ VITE_EMAIL_SERVICE_URL:", import.meta.env.VITE_EMAIL_SERVICE_URL);
      
    try {
      const url = `${import.meta.env.VITE_EMAIL_SERVICE_URL}/email/subscribe`;
      console.log("✅ VITE_EMAIL_SERVICE_URL:", import.meta.env.VITE_EMAIL_SERVICE_URL);
      console.log("📬 Submitting to:", url);
  
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
  
      // Success message
      toast.success("Request Received!", {
        description: "Thanks for subscribing! Shortly you will receive a confirmation email with more details about our beta program.",
        duration: 5000,
        position: "top-center",
        style: { minWidth: "350px" },
      });
  
      setEmail('');
    } catch (error) {
      console.error("Error submitting email:", error);
      toast.error("Something went wrong", {
        description: "Please try again later.",
        duration: 4000,
        position: "top-center",
        style: { minWidth: "350px" },
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  
  
  return (
    <section className="relative min-h-screen pt-20 flex flex-col items-center justify-center overflow-hidden">
      {/* Background elements */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div className="absolute top-1/4 left-1/4 w-72 h-72 bg-primary/10 rounded-full filter blur-3xl animate-float opacity-70"></div>
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-accent/10 rounded-full filter blur-3xl animate-pulse-slow opacity-70"></div>
        <div className="absolute top-1/2 right-1/3 w-64 h-64 bg-accent/10 rounded-full filter blur-3xl animate-float opacity-70" style={{ animationDelay: '2s' }}></div>
      </div>
      
      <div className="w-full max-w-5xl mx-auto px-4 flex flex-col items-center text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-8 animate-fade-in">
          <div className="w-2 h-2 rounded-full bg-primary mr-2"></div>
          Coming Soon
        </div>
        
        <h1 className="text-4xl md:text-6xl font-display font-bold leading-tight md:leading-tight mb-6 animate-fade-in" style={{ animationDelay: '0.1s' }}>
          Ecommerce Analytics <br className="hidden md:block" />
          <span className="gradient-text">For Data-Driven Growth</span>
        </h1>
        
        <p className="text-lg md:text-xl text-muted-foreground max-w-2xl mb-10 animate-fade-in" style={{ animationDelay: '0.2s' }}>
          Dougie Analytics connects all your sales and marketing data to help you make better decisions, optimize ad spend, and drive profitable growth.
        </p>

        <div className="glass-panel-premium w-full max-w-md rounded-xl p-8 mb-10 animate-fade-in" style={{ animationDelay: '0.3s' }}>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <h3 className="text-lg font-medium mb-2">Join our beta program</h3>
            <div className="flex space-x-2">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 rounded-lg border border-input bg-background/70 px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button 
                type="submit"
                className="rounded-lg bg-primary text-primary-foreground hover:bg-primary/90 px-4 py-2 text-sm font-medium transition-colors"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Request Access'}
              </button>
            </div>
            <p className="text-xs text-muted-foreground">Join 500+ merchants already on our waitlist.</p>
          </form>
        </div>
        
        {/* Improved E-commerce & Advertising Dashboard Preview */}
        <div className="relative w-full max-w-4xl aspect-video rounded-xl overflow-hidden glass-panel-premium animate-fade-in" style={{ animationDelay: '0.4s' }}>
          <div className="absolute inset-0 p-3 sm:p-6">
            {/* Dashboard Header */}
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                <div className="w-3 h-3 bg-green-500 rounded-full"></div>
              </div>
              <div className="flex items-center space-x-2 text-xs">
                <span className="px-2 py-1 bg-primary/20 rounded-md text-primary font-medium">Last 30 days</span>
                <span className="px-2 py-1 bg-background/60 rounded-md">vs Previous</span>
                <span className="px-2 py-1 bg-background/60 rounded-md">YoY</span>
                <span className="px-2 py-1 bg-background/60 rounded-md">QoQ</span>
              </div>
            </div>
            
            {/* Dashboard Content - Improved KPIs */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 mb-4">
              {/* KPI Cards with improved design */}
              {[
                { label: 'Revenue', value: '$128,432', trend: '+12.3%', color: 'text-green-500', icon: 'graph' },
                { label: 'Conversion Rate', value: '4.28%', trend: '+0.8%', color: 'text-green-500', icon: 'percent' },
                { label: 'Avg. Order Value', value: '$86.23', trend: '+5.2%', color: 'text-green-500', icon: 'shopping' },
                { label: 'ROAS', value: '3.6x', trend: '-0.4%', color: 'text-red-500', icon: 'target' }
              ].map((kpi, index) => (
                <div key={index} className="bg-background/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 relative overflow-hidden hover:shadow-md transition-all duration-300 border border-background/20">
                  <div className="absolute top-0 right-0 w-16 h-16 rounded-full bg-primary/5 -translate-y-1/2 translate-x-1/2"></div>
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xs sm:text-sm text-muted-foreground font-medium">{kpi.label}</h3>
                      <p className="text-lg sm:text-xl md:text-2xl font-bold mt-1">{kpi.value}</p>
                      <span className={`text-xs ${kpi.color} flex items-center mt-1`}>
                        {kpi.trend}
                        <svg className="w-3 h-3 ml-1" viewBox="0 0 24 24" fill="none" 
                          style={{ transform: kpi.trend.startsWith('+') ? 'none' : 'rotate(180deg)' }}>
                          <path d="M12 5L19 12L12 19M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </div>
                    <div className={`hidden md:flex w-8 h-8 rounded-full bg-primary/10 items-center justify-center ${kpi.color.replace('text', 'bg')}/10`}>
                      {kpi.icon === 'graph' && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" 
                          style={{ transform: kpi.trend.startsWith('+') ? 'none' : 'rotate(180deg)' }}>
                          <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"/>
                          <polyline points="17 6 23 6 23 12"/>
                        </svg>
                      )}
                      {kpi.icon === 'percent' && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-primary">
                          <line x1="19" y1="5" x2="5" y2="19"/>
                          <circle cx="6.5" cy="6.5" r="2.5"/>
                          <circle cx="17.5" cy="17.5" r="2.5"/>
                        </svg>
                      )}
                      {kpi.icon === 'shopping' && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-primary">
                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"/>
                          <line x1="3" y1="6" x2="21" y2="6"/>
                          <path d="M16 10a4 4 0 0 1-8 0"/>
                        </svg>
                      )}
                      {kpi.icon === 'target' && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-primary">
                          <circle cx="12" cy="12" r="10"/>
                          <circle cx="12" cy="12" r="6"/>
                          <circle cx="12" cy="12" r="2"/>
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Charts Row - Top - Improved Revenue by Channel with actual data */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 h-40 md:h-44 mb-2 sm:mb-4">
              {/* Revenue Chart - Enhanced with more realistic data visualization */}
              <div className="md:col-span-2 bg-background/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 border border-background/20">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-xs sm:text-sm font-medium">Revenue by Channel</h3>
                  <div className="flex space-x-3 text-[10px]">
                    <div className="flex items-center">
                      <span className="w-2 h-2 bg-blue-500 rounded-full inline-block mr-1"></span>
                      <span>Shopify</span>
                    </div>
                    <div className="flex items-center">
                      <span className="w-2 h-2 bg-purple-500 rounded-full inline-block mr-1"></span>
                      <span>Amazon</span>
                    </div>
                    <div className="flex items-center">
                      <span className="w-2 h-2 bg-green-500 rounded-full inline-block mr-1"></span>
                      <span>Etsy</span>
                    </div>
                  </div>
                </div>
                <div className="h-24 sm:h-28 relative">
                  {/* Chart data with stacked bar chart simulation */}
                  <div className="absolute inset-x-0 bottom-0 flex items-end h-full space-x-1 px-1">
                    {[
                      {shopify: 45, amazon: 30, etsy: 15},
                      {shopify: 50, amazon: 25, etsy: 10},
                      {shopify: 40, amazon: 35, etsy: 12},
                      {shopify: 55, amazon: 20, etsy: 18},
                      {shopify: 60, amazon: 25, etsy: 15},
                      {shopify: 50, amazon: 30, etsy: 20},
                      {shopify: 65, amazon: 25, etsy: 10},
                      {shopify: 70, amazon: 20, etsy: 10},
                      {shopify: 60, amazon: 30, etsy: 15},
                      {shopify: 75, amazon: 25, etsy: 10},
                      {shopify: 80, amazon: 20, etsy: 15},
                      {shopify: 70, amazon: 30, etsy: 20}
                    ].map((data, i) => (
                      <div key={i} className="relative flex-1 group h-full flex flex-col justify-end">
                        <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-black/70 text-white text-[8px] px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none whitespace-nowrap">
                          ${(data.shopify + data.amazon + data.etsy) * 100}
                        </div>
                        <div className="bg-green-500 w-full rounded-sm" style={{ height: `${data.etsy}%` }}></div>
                        <div className="bg-purple-500 w-full rounded-sm" style={{ height: `${data.amazon}%` }}></div>
                        <div className="bg-blue-500 w-full rounded-sm" style={{ height: `${data.shopify}%` }}></div>
                        <div className="absolute bottom-0 left-0 right-0 h-full opacity-0 group-hover:opacity-20 bg-white transition-opacity"></div>
                        <span className="absolute bottom-[-14px] left-1/2 transform -translate-x-1/2 text-[8px] text-muted-foreground">
                          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][i]}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {/* Ad Performance - Enhanced design */}
              <div className="bg-background/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 border border-background/20">
                <h3 className="text-xs sm:text-sm font-medium mb-2">Ad Performance</h3>
                <div className="space-y-2">
                  {[
                    { platform: 'Google', spend: '$24.2K', cpc: '$1.28', color: 'bg-blue-500' },
                    { platform: 'Facebook', spend: '$18.6K', cpc: '$0.92', color: 'bg-indigo-500' },
                    { platform: 'Instagram', spend: '$12.3K', cpc: '$1.43', color: 'bg-purple-500' }
                  ].map((ad, index) => (
                    <div key={index} className="flex items-center justify-between text-xs hover:bg-background/40 p-1 rounded transition-colors">
                      <div className="flex items-center">
                        <div className={`w-1.5 h-1.5 ${ad.color} rounded-full mr-2`}></div>
                        <span>{ad.platform}</span>
                      </div>
                      <div className="flex space-x-2">
                        <span className="text-muted-foreground">{ad.spend}</span>
                        <span className="px-1 bg-background/80 rounded text-[10px]">{ad.cpc}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-full h-10 mt-3 bg-muted/30 rounded overflow-hidden">
                  <div className="h-full flex">
                    <div className="h-full w-[44%] bg-blue-500/70 rounded-l flex items-center justify-center text-[9px] text-white font-medium">44%</div>
                    <div className="h-full w-[33%] bg-indigo-500/70 flex items-center justify-center text-[9px] text-white font-medium">33%</div>
                    <div className="h-full w-[23%] bg-purple-500/70 rounded-r flex items-center justify-center text-[9px] text-white font-medium">23%</div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Charts Row - Bottom - Enhanced with better visuals */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-4 h-40 md:h-44">
              {/* YoY Comparison Chart - Improved */}
              <div className="bg-background/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 border border-background/20">
                <h3 className="text-xs sm:text-sm font-medium mb-2">YoY Growth</h3>
                <div className="flex items-center justify-between mb-2">
                  <div className="flex-1 h-24 relative">
                    <div className="absolute inset-0 flex items-end">
                      <div className="w-1/4 h-full flex flex-col justify-end items-center px-1 group">
                        <div className="w-full bg-blue-400/90 rounded-t h-[40%] relative group-hover:h-[42%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+30%</span>
                        </div>
                        <div className="w-2/3 bg-green-400/90 rounded-t h-[25%] relative mt-[-1px] group-hover:h-[27%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+18%</span>
                        </div>
                        <span className="text-[8px] mt-1 text-muted-foreground">Q1</span>
                      </div>
                      <div className="w-1/4 h-full flex flex-col justify-end items-center px-1 group">
                        <div className="w-full bg-blue-400/90 rounded-t h-[55%] relative group-hover:h-[57%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+42%</span>
                        </div>
                        <div className="w-2/3 bg-green-400/90 rounded-t h-[35%] relative mt-[-1px] group-hover:h-[37%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+26%</span>
                        </div>
                        <span className="text-[8px] mt-1 text-muted-foreground">Q2</span>
                      </div>
                      <div className="w-1/4 h-full flex flex-col justify-end items-center px-1 group">
                        <div className="w-full bg-blue-400/90 rounded-t h-[65%] relative group-hover:h-[67%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+48%</span>
                        </div>
                        <div className="w-2/3 bg-green-400/90 rounded-t h-[45%] relative mt-[-1px] group-hover:h-[47%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+33%</span>
                        </div>
                        <span className="text-[8px] mt-1 text-muted-foreground">Q3</span>
                      </div>
                      <div className="w-1/4 h-full flex flex-col justify-end items-center px-1 group">
                        <div className="w-full bg-blue-400/90 rounded-t h-[75%] relative group-hover:h-[77%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+52%</span>
                        </div>
                        <div className="w-2/3 bg-green-400/90 rounded-t h-[55%] relative mt-[-1px] group-hover:h-[57%] transition-all">
                          <span className="absolute -top-4 left-1/2 transform -translate-x-1/2 text-[8px] bg-black/70 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity">+41%</span>
                        </div>
                        <span className="text-[8px] mt-1 text-muted-foreground">Q4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center space-x-4 text-[10px]">
                  <div className="flex items-center">
                    <span className="w-2 h-2 bg-blue-400 rounded-full inline-block mr-1"></span>
                    <span>2024</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-2 h-2 bg-green-400 rounded-full inline-block mr-1"></span>
                    <span>2023</span>
                  </div>
                </div>
              </div>
              
              {/* Social Media Analytics - Enhanced with better layout and visual hierarchy */}
              <div className="md:col-span-2 bg-background/60 backdrop-blur-sm rounded-lg p-3 sm:p-4 border border-background/20">
                <h3 className="text-xs sm:text-sm font-medium mb-3">Social Media ROI</h3>
                <div className="grid grid-cols-4 gap-2">
                  {[
                    { platform: 'Facebook', spend: '$12.5K', sales: '$42.8K', roi: '3.4x', color: 'bg-blue-500', icon: 'facebook' },
                    { platform: 'Instagram', spend: '$18.3K', sales: '$67.2K', roi: '3.7x', color: 'bg-purple-500', icon: 'instagram' },
                    { platform: 'TikTok', spend: '$8.7K', sales: '$29.6K', roi: '3.4x', color: 'bg-pink-500', icon: 'tiktok' },
                    { platform: 'Pinterest', spend: '$5.2K', sales: '$19.8K', roi: '3.8x', color: 'bg-red-500', icon: 'pinterest' }
                  ].map((platform, index) => (
                    <div key={index} className="bg-background/40 rounded-lg p-2 text-xs relative overflow-hidden hover:bg-background/60 transition-all">
                      <div className={`absolute top-0 left-0 w-1 h-full ${platform.color}`}></div>
                      <div className="pl-2">
                        <div className="flex items-center mb-1.5">
                          <div className={`w-3 h-3 rounded-full ${platform.color} flex items-center justify-center mr-1.5`}>
                            {platform.icon === 'facebook' && <span className="text-[6px] text-white font-bold">f</span>}
                            {platform.icon === 'instagram' && <span className="text-[6px] text-white font-bold">i</span>}
                            {platform.icon === 'tiktok' && <span className="text-[6px] text-white font-bold">t</span>}
                            {platform.icon === 'pinterest' && <span className="text-[6px] text-white font-bold">p</span>}
                          </div>
                          <h4 className="font-semibold text-[11px]">{platform.platform}</h4>
                        </div>
                        <div className="mt-1 space-y-1 text-[10px]">
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">Spend:</span>
                            <span>{platform.spend}</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">Sales:</span>
                            <span>{platform.sales}</span>
                          </div>
                          <div className="flex justify-between font-medium">
                            <span className="text-muted-foreground">ROI:</span>
                            <span className="text-green-500">{platform.roi}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Trusted By Section */}
        <div className="mt-16 w-full">
          <p className="text-sm text-muted-foreground mb-6">TRUSTED BY INNOVATIVE BRANDS</p>
          <div className="flex flex-wrap justify-center items-center gap-8 opacity-70">
            {/* Placeholder logos - you would replace these with actual client logos */}
            {[1, 2, 3, 4, 5].map(i => (
              <div key={i} className="h-8 bg-muted/30 rounded w-24 md:w-32"></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}