import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  LayoutDashboard, Store, LineChart, ShoppingCart,
  Users, Megaphone
} from 'lucide-react';
import { IconType } from 'react-icons';
import UserGreeting from '@/components/UserGreeting';

const sidebarItems: SidebarItem[] = [
  { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
  { name: 'Shopify', href: '/connect-shopify', icon: Store },
  { name: 'Ad Platforms', href: '/connect-ads', icon: Megaphone },
  { name: 'Analytics', href: '/analytics', icon: LineChart, badge: 'Soon' },
  { name: 'Products', href: '/products', icon: ShoppingCart, badge: 'Soon' },
  { name: 'Customers', href: '/customers', icon: Users, badge: 'Soon' },
];

type SidebarItem = {
  name: string;
  href: string;
  icon: IconType;
  badge?: string;
};

export default function DashboardLayout({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    setIsAuthenticated(isLoggedIn);

    if (!isLoggedIn && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [navigate, location.pathname]);

  if (!isAuthenticated && location.pathname !== '/login') {
    return null;
  }

  return (
    <div className="min-h-screen bg-background flex">
      {/* Sidebar */}
      <aside className={cn(
        "fixed top-0 bottom-0 left-0 bg-card border-r z-20 w-64 transition-transform duration-300 ease-in-out",
        isSidebarOpen ? "transform-none" : "-translate-x-full md:translate-x-0 md:w-20"
      )}>
        <div className="h-full flex flex-col">
          {/* Logo */}
          <div className="flex items-center justify-center py-4">
            <Link to="/" className="flex items-center gap-2">
              <div className="h-9 w-9 rounded-full bg-gradient-to-br from-primary to-accent flex items-center justify-center text-white font-semibold text-lg">
                D
              </div>
              {isSidebarOpen && (
                <span className="font-display text-lg font-semibold text-foreground">
                  Dougie Analytics
                </span>
              )}
            </Link>
          </div>

          {/* Navigation */}
          <nav className="flex-1 px-2 py-4 space-y-1 mt-4">
            {sidebarItems.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={cn(
                  "flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors",
                  location.pathname === item.href
                    ? "bg-primary/10 text-primary"
                    : "text-muted-foreground hover:bg-muted hover:text-foreground"
                )}
              >
                <div className={cn("flex items-center", isSidebarOpen ? "justify-start gap-3" : "justify-center w-full")}
                >
                  <item.icon className="h-5 w-5" />
                  {isSidebarOpen && (
                    <div className="flex items-center justify-between w-full">
                      <span>{item.name}</span>
                      {item.badge && (
                        <span className="text-xs px-1.5 py-0.5 rounded-full bg-primary/10 text-primary">
                          {item.badge}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </nav>
        </div>
      </aside>

      {/* Main content area */}
      <div className={cn(
        "flex-1 transition-all duration-300 ease-in-out relative",
        isSidebarOpen ? "md:ml-64" : "md:ml-20"
      )}>
        <div className="absolute top-4 right-6 z-10">
          <UserGreeting />
        </div>
        <div className="min-h-screen pt-[80px] px-4">{children}</div>
      </div>
    </div>
  );
}
