
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Progress } from '@/components/ui/progress';
import { CheckCircle2, Database, BarChart3, ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import DashboardLayout from '@/components/DashboardLayout';

export default function DataLoading() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  
  useEffect(() => {
    // Mock progress simulation
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(timer);
          setIsComplete(true);
          return 100;
        }
        
        // Update the current step based on progress
        if (prev >= 33 && prev < 66) {
          setCurrentStep(2);
        } else if (prev >= 66) {
          setCurrentStep(3);
        }
        
        return prev + 2;
      });
    }, 300);
    
    return () => clearInterval(timer);
  }, []);
  
  const handleContinue = () => {
    navigate('/dashboard');
  };
  
  const steps = [
    { id: 1, title: "Retrieving Shopify data", icon: <Database className="h-6 w-6" /> },
    { id: 2, title: "Processing and storing", icon: <Database className="h-6 w-6" /> },
    { id: 3, title: "Building dashboards", icon: <BarChart3 className="h-6 w-6" /> },
  ];
  
  return (
    <DashboardLayout>
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Setting up your analytics</h1>
          <p className="text-lg text-muted-foreground">
            We're processing your Shopify data to create your personalized dashboards.
          </p>
        </div>
        
        <div className="bg-card rounded-lg border p-8 shadow-sm mb-8">
          <Progress value={progress} className="h-2 mb-10" />
          
          <div className="space-y-6">
            {steps.map((step) => (
              <div 
                key={step.id} 
                className={`flex items-start gap-4 ${step.id > currentStep ? 'opacity-50' : ''}`}
              >
                <div className={`rounded-full p-2 ${
                  step.id < currentStep ? 'bg-green-100' : 
                  step.id === currentStep ? 'bg-primary/10' : 'bg-muted'
                }`}>
                  {step.id < currentStep ? (
                    <CheckCircle2 className="h-6 w-6 text-green-600" />
                  ) : (
                    <div className={`${
                      step.id === currentStep ? 'text-primary' : 'text-muted-foreground'
                    }`}>
                      {step.icon}
                    </div>
                  )}
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-1">{step.title}</h3>
                  <p className="text-muted-foreground">
                    {step.id === 1 && "Securely accessing your Shopify store data including sales, products, and customers."}
                    {step.id === 2 && "Organizing and processing your data for analysis in BigQuery and Google Cloud Storage."}
                    {step.id === 3 && "Creating visualizations and insights from your processed data."}
                  </p>
                </div>
              </div>
            ))}
          </div>
          
          {isComplete && (
            <div className="mt-10 text-center">
              <div className="inline-flex items-center justify-center rounded-full bg-green-100 p-3 mb-4">
                <CheckCircle2 className="h-8 w-8 text-green-600" />
              </div>
              <h3 className="text-xl font-medium mb-2">Setup Complete!</h3>
              <p className="text-muted-foreground mb-6">
                Your data has been processed and your dashboards are ready to view.
              </p>
              <Button 
                onClick={handleContinue} 
                className="gap-2"
              >
                View Dashboard <ArrowRight className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
        
        {!isComplete && (
          <div className="text-center text-muted-foreground text-sm">
            <p>This may take a few minutes. Please don't close this page.</p>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}