import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { signInWithPopup, UserCredential } from "firebase/auth";
import { auth, provider, db } from "@/firebaseConfig";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleGoogleLogin = async () => {
    try {
      console.log("🔄 Signing in with Google...");
      provider.setCustomParameters({ prompt: "select_account" });

      const result: UserCredential = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("✅ Google Sign-In Successful:", user);

      // Store auth token
      const token = await user.getIdToken();
      localStorage.setItem("auth_token", token);
      console.log("🔐 Token stored in localStorage:", token);

      // Store user in Firestore if not exists
      const userRef = doc(db, "users", user.uid);
      const userSnapshot = await getDoc(userRef);

      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          tenantId: "default_tenant",
          createdAt: new Date(),
        });
        console.log("✅ New user document created in Firestore");
      } else {
        console.log("✅ User already exists in Firestore");
      }

      toast({
        title: "Login Successful",
        description: `Welcome, ${user.displayName}!`,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("❌ Google Login Failed", error);
      toast({
        title: "Login Failed",
        description: (error as Error).message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-background via-background to-muted/30">
      <div className="flex-1 hidden lg:block relative">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&auto=format&fit=crop&w=1950&q=80')",
            opacity: 0.7,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-background/80 to-transparent" />
        <div className="absolute top-1/3 left-12 max-w-md p-8">
          <div className="flex items-center gap-2 mb-6">
            <div className="h-10 w-10 rounded-lg bg-primary flex items-center justify-center text-white font-semibold text-lg">
              D
            </div>
            <span className="font-display text-2xl font-bold gradient-text">
              Dougie Analytics
            </span>
          </div>
          <h1 className="text-4xl font-bold text-foreground mb-4">
            Turn data into business growth
          </h1>
          <p className="text-muted-foreground text-lg">
            Connect your Shopify store and advertising platforms to get insights
            that drive real results.
          </p>
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-center items-center p-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold">Welcome back</h2>
            <p className="text-muted-foreground mt-2">Login to your dashboard</p>
          </div>

          {/* Google Login Button */}
          <Button
            onClick={handleGoogleLogin}
            className="w-full bg-white text-black border border-gray-300 hover:bg-gray-100"
          >
            <img
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              alt="Google"
              className="h-5 w-5 mr-2"
            />
            Continue with Google
          </Button>

          {/* Email/Password Login UI (Not implemented yet) */}
          <form className="mt-8 space-y-6">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-foreground mb-1"
                >
                  Email address
                </label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@company.com"
                  required
                  className="w-full"
                />
              </div>

              <div>
                <div className="flex items-center justify-between mb-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-foreground"
                  >
                    Password
                  </label>
                  <a href="#" className="text-sm text-primary hover:text-primary/80">
                    Forgot password?
                  </a>
                </div>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  required
                  className="w-full"
                />
              </div>
            </div>

            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? "Logging in..." : "Log in"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
