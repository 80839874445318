import { Navigate } from "react-router-dom";
import { useAuthContext } from "@/context/AuthProvider"; // ✅ use this instead

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const { user, tenant, loading } = useAuthContext(); // ✅ this reads from context

  console.log("🔍 Checking PrivateRoute...");
  console.log("🧑 User:", user);
  console.log("🏢 Tenant:", tenant);
  console.log("⏳ Loading:", loading);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!tenant) {
    return <Navigate to="/no-access" replace />;
  }

  return children;
}
