import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";

// ✅ Firebase Config
const firebaseConfig = {
  apiKey: "AIzaSyAr4V5b29VHn2VRGnD5KaCRsQxWQdLpfN4",
  authDomain: "dougieanalyticspoc.firebaseapp.com",
  projectId: "dougieanalyticspoc",
  storageBucket: "dougieanalyticspoc.appspot.com",
  messagingSenderId: "882510010805",
  appId: "1:882510010805:web:61b9a95927bb60dcbd21fc",
};

// ✅ Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,  // ✅ <-- Important
});
const provider = new GoogleAuthProvider();

// ✅ Ensure Auth Persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => console.log("🔄 Firebase Auth Persistence Enabled"))
  .catch((error) => console.error("❌ Error setting persistence:", error));

export { auth, provider, db };
