// import { Github, Linkedin, Twitter } from 'lucide-react';

// export default function Footer() {
//   const currentYear = new Date().getFullYear();
  
//   return (
//     <footer className="py-16 px-4 bg-gradient-to-b from-background to-muted/20">
//       <div className="max-w-7xl mx-auto">
//         <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-10">
//           <div className="lg:col-span-2">
//             <div className="flex items-center gap-2 mb-4">
//               <div className="h-10 w-10 rounded-lg bg-primary flex items-center justify-center text-white font-semibold text-lg">D</div>
//               <span className="font-display text-2xl font-bold gradient-text">Dougie Analytics</span>
//             </div>
//             <p className="text-sm text-muted-foreground mt-2 max-w-xs">
//               Transforming how businesses understand their data with intelligent analytics and actionable insights.
//             </p>
//             <div className="flex space-x-4 mt-6">
//               <a href="#" className="h-10 w-10 rounded-full bg-foreground/5 flex items-center justify-center text-foreground/80 hover:bg-foreground/10 hover:text-foreground transition-colors">
//                 <Twitter className="h-5 w-5" />
//                 <span className="sr-only">Twitter</span>
//               </a>
//               <a href="#" className="h-10 w-10 rounded-full bg-foreground/5 flex items-center justify-center text-foreground/80 hover:bg-foreground/10 hover:text-foreground transition-colors">
//                 <Linkedin className="h-5 w-5" />
//                 <span className="sr-only">LinkedIn</span>
//               </a>
//               <a href="#" className="h-10 w-10 rounded-full bg-foreground/5 flex items-center justify-center text-foreground/80 hover:bg-foreground/10 hover:text-foreground transition-colors">
//                 <Github className="h-5 w-5" />
//                 <span className="sr-only">GitHub</span>
//               </a>
//             </div>
//           </div>
          
//           <div>
//             <h4 className="font-medium mb-5 text-lg">Platform</h4>
//             <ul className="space-y-3">
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">1-click Integrations</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Analytics Dashboard</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Custom Reporting</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Goals Tracking</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Smart Alerts & Insights</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Ask Dougie AI</a></li>
//             </ul>
//           </div>
          
//           <div>
//             <h4 className="font-medium mb-5 text-lg">Connect With</h4>
//             <ul className="space-y-3">
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">E-commerce Platforms</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Marketing Tools</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Ad Networks</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">CRM Systems</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Cloud Services</a></li>
//             </ul>
//           </div>
          
//           <div>
//             <h4 className="font-medium mb-5 text-lg">Solutions</h4>
//             <ul className="space-y-3">
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Customer Journey Mapping</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Revenue Optimization</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Marketing ROI</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Data Visualization</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Multi-Channel Analytics</a></li>
//             </ul>
//           </div>
          
//           <div>
//             <h4 className="font-medium mb-5 text-lg">Company</h4>
//             <ul className="space-y-3">
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">About Us</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Blog</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Careers</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Contact</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Privacy Policy</a></li>
//               <li><a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors">Terms of Service</a></li>
//             </ul>
//           </div>
//         </div>
        
//         <div className="mt-16 pt-8 border-t flex flex-col md:flex-row justify-between items-center">
//           <p className="text-sm text-muted-foreground">
//             &copy; {currentYear} Dougie Analytics. All rights reserved.
//           </p>
          
//           <div className="mt-4 md:mt-0">
//             <a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors mx-3">Privacy</a>
//             <a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors mx-3">Terms</a>
//             <a href="#" className="text-sm text-muted-foreground hover:text-primary transition-colors mx-3">Cookies</a>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

export default function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="py-8 px-4 bg-gradient-to-b from-background to-muted/20">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center gap-2 mb-4">
            <div className="h-10 w-10 rounded-lg bg-primary flex items-center justify-center text-white font-semibold text-lg">D</div>
            <span className="font-display text-2xl font-bold gradient-text">Dougie Analytics</span>
          </div>
          <p className="text-sm text-muted-foreground text-center max-w-md mb-6">
            Transforming how businesses understand their data with intelligent analytics and actionable insights.
          </p>
          <p className="text-sm text-muted-foreground">
            &copy; {currentYear} Dougie Analytics. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
