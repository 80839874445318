import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { Check, Instagram, Facebook, ArrowLeft, Globe, Megaphone } from 'lucide-react';
import { FaPinterest, FaTiktok } from "react-icons/fa6";
import { IconType } from "react-icons";
import DashboardLayout from '@/components/DashboardLayout';

type Platform = {
  id: string;
  name: string;
  icon: IconType;
  connected: boolean;
  description: string;
};

export default function ConnectAds() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const [platforms, setPlatforms] = useState<Platform[]>([
    { id: 'instagram', name: 'Instagram', icon: Instagram, connected: false, description: 'Connect your Instagram Ads account to track campaign performance.' },
    { id: 'facebook', name: 'Facebook', icon: Facebook, connected: false, description: 'Connect your Facebook Ads account to analyze ad spend and results.' },
    { id: 'pinterest', name: 'Pinterest', icon: FaPinterest, connected: false, description: 'Connect Pinterest Ads to track engagement and conversions.' },
    { id: 'tiktok', name: 'TikTok', icon: FaTiktok, connected: false, description: 'Connect TikTok Ads to measure performance and ROI.' },
  ]);

  const handleConnect = (platformId: string) => {
    setPlatforms(platforms.map(platform => 
      platform.id === platformId 
        ? { ...platform, connected: true } 
        : platform
    ));

    toast({
      title: "Success",
      description: `Connected to ${platformId} successfully`,
    });
  };

  const handleFinish = () => {
    navigate('/dashboard');

    toast({
      title: "All set!",
      description: "Your ad platforms are now connected and data will begin syncing.",
    });
  };

  const connectedCount = platforms.filter(p => p.connected).length;

  return (
    <DashboardLayout>
      <div className="max-w-5xl mx-auto px-4 py-12">
        <Button variant="ghost" className="mb-8 gap-2" onClick={() => navigate('/dashboard')}>
          <ArrowLeft className="h-4 w-4" /> Back to dashboard
        </Button>

        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Connect your ad platforms</h1>
          <p className="text-lg text-muted-foreground max-w-2xl mx-auto">
            Link your advertising accounts to get a complete view of your marketing performance.
          </p>
        </div>

        <div className="bg-card rounded-lg border p-8 shadow-sm mb-8">
          <div className="flex items-center gap-4 mb-8">
            <div className="bg-primary/10 rounded-full p-3">
              <Megaphone className="h-6 w-6 text-primary" />
            </div>
            <div>
              <h3 className="text-xl font-semibold">Ad Platform Connections</h3>
              <p className="text-muted-foreground">Connect the platforms you use for advertising</p>
            </div>
          </div>

          <div className="grid gap-4 md:grid-cols-2 mb-8">
            {platforms.map((platform) => (
              <div key={platform.id} className="border rounded-lg p-6 flex flex-col">
                <div className="flex justify-between items-start mb-4">
                  <div className="flex items-center gap-3">
                    <div className="bg-muted/80 rounded-full p-2">
                      <platform.icon className="h-6 w-6" />  {/* ✅ Correct JSX usage */}
                    </div>
                    <h3 className="text-lg font-semibold">{platform.name}</h3>
                  </div>
                  {platform.connected && (
                    <div className="flex items-center text-sm text-green-600 gap-1">
                      <Check className="h-4 w-4" />
                      Connected
                    </div>
                  )}
                </div>

                <p className="text-muted-foreground text-sm mb-6 flex-1">
                  {platform.description}
                </p>

                <Button
                  variant={platform.connected ? "outline" : "default"}
                  className="self-start"
                  onClick={() => handleConnect(platform.id)}
                  disabled={platform.connected}
                >
                  {platform.connected ? "Connected" : "Connect"}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
