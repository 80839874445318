import { useState } from 'react';
import DashboardLayout from '@/components/DashboardLayout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { AreaChart, BarChart, PieChart } from 'recharts';
import { Area, Bar, CartesianGrid, Cell, Legend, Pie, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ArrowUpRight, ArrowDownRight, Plus, ShoppingCart, Users, DollarSign, Percent } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';

// Mock data
const salesData = [
  { name: 'Jan', value: 4000 },
  { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 5000 },
  { name: 'Apr', value: 4500 },
  { name: 'May', value: 6000 },
  { name: 'Jun', value: 5500 },
  { name: 'Jul', value: 7000 },
];

const channelData = [
  { name: 'Instagram', value: 35, color: '#E1306C' },
  { name: 'Facebook', value: 25, color: '#1877F2' },
  { name: 'Organic', value: 20, color: '#4CAF50' },
  { name: 'Pinterest', value: 15, color: '#E60023' },
  { name: 'TikTok', value: 5, color: '#000000' },
];

const productData = [
  { name: 'Product A', sales: 120, revenue: 5400 },
  { name: 'Product B', sales: 98, revenue: 4900 },
  { name: 'Product C', sales: 86, revenue: 4300 },
  { name: 'Product D', sales: 72, revenue: 3600 },
  { name: 'Product E', sales: 65, revenue: 3250 },
];

export default function Dashboard() {
  const [period, setPeriod] = useState('30d');
  const { user } = useAuth();

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Greeting shown top-right of the page */}
        {/* {user && (
  <div className="flex justify-end mb-4">
    <div className="flex items-center gap-2 bg-white border border-muted-foreground/20 px-4 py-2 rounded-2xl shadow-md text-muted-foreground text-sm md:text-base font-medium">
      👋 {getGreeting()}, <span className="font-semibold text-foreground">{user.displayName?.split(" ")[0]}</span>!
    </div>
  </div>
)} */}


        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8 gap-4">
          <div>
            <h1 className="text-3xl font-bold">Dashboard</h1>
            <p className="text-muted-foreground">
              Your Shopify store analytics overview
            </p>
          </div>

          <div className="flex items-center gap-4">
            <Tabs defaultValue={period} className="w-[400px]" onValueChange={setPeriod}>
              <TabsList>
                <TabsTrigger value="7d">7 days</TabsTrigger>
                <TabsTrigger value="30d">30 days</TabsTrigger>
                <TabsTrigger value="90d">90 days</TabsTrigger>
                <TabsTrigger value="12m">12 months</TabsTrigger>
              </TabsList>
            </Tabs>

            <Link to="/connect-ads">
              <Button variant="outline" className="gap-2">
                <Plus className="h-4 w-4" /> Connect Ads
              </Button>
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {/* ... scorecards ... */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">Total Revenue</CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">$24,567.85</div>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <span className="flex items-center text-emerald-500">
                  <ArrowUpRight className="h-4 w-4 mr-1" />
                  12.5%
                </span>
                vs previous period
              </div>
            </CardContent>
          </Card>

          {/* ... Other cards ... */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">Orders</CardTitle>
              <ShoppingCart className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">453</div>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <span className="flex items-center text-emerald-500">
                  <ArrowUpRight className="h-4 w-4 mr-1" />
                  8.2%
                </span>
                vs previous period
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">Conversion Rate</CardTitle>
              <Percent className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">3.24%</div>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <span className="flex items-center text-rose-500">
                  <ArrowDownRight className="h-4 w-4 mr-1" />
                  0.4%
                </span>
                vs previous period
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium">New Customers</CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">175</div>
              <div className="flex items-center gap-1 text-sm text-muted-foreground">
                <span className="flex items-center text-emerald-500">
                  <ArrowUpRight className="h-4 w-4 mr-1" />
                  18.3%
                </span>
                vs previous period
              </div>
            </CardContent>
          </Card>
        </div>

        {/* ... chart sections and table ... */}
        {/* Keep everything below as is */}
      </div>
    </DashboardLayout>
  );
}
