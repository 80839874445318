import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import ConnectShopify from "./pages/ConnectShopify";
import DataLoading from "./pages/DataLoading";
import Dashboard from "./pages/Dashboard";
import ConnectAds from "./pages/ConnectAds";
import { PrivateRoute } from "@/routes/PrivateRoute";
import { AuthProvider } from "@/context/AuthProvider"; // ✅

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Toaster />
      <Sonner />
      <AuthProvider> {/* ✅ Wrap app here */}
        <BrowserRouter>
        <Routes>
  <Route path="/" element={<Index />} />
  <Route path="/login" element={<Login />} />
  <Route path="/welcome" element={<Welcome />} />
  <Route path="/connect-shopify" element={<ConnectShopify />} />
  <Route path="/data-loading" element={<DataLoading />} />
  <Route path="/data-dashboard" element={<DataLoading />} />
  <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
  <Route path="/connect-ads" element={<PrivateRoute><ConnectAds /></PrivateRoute>} />
  <Route path="/about" element={<PrivateRoute><About /></PrivateRoute>} />
  <Route path="*" element={<NotFound />} />
</Routes>

        </BrowserRouter>
      </AuthProvider>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
